import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import Tiles from './tiles/Tiles'

let Menu = ({}) => {
  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)

  return (
    <div style={styles.page}>
      {tab == 'wallet' && <div>wallets</div>}
      {tab == 'home' && <Tiles />}
    </div>
  )
}

let styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 330,
    alignSelf: 'center',
  },
}

export default Menu
