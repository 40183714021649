import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import share from '../imgs/shareWhite.png'
import plusSquare from '../imgs/plusSquareWhite.png'
import chromeMenu from '../imgs/chromeMenuWhite.png'
import phone from '../imgs/phoneWhite.png'

let Install = ({}) => {
  let device = useSelector(state => state.device)
  let browser = useSelector(state => state.browser)
  let screenHeight = useSelector(state => state.screenHeight)
  let topHalf = screenHeight - 300 //220 + 80

  useEffect(() => {
    document.body.style.backgroundColor = '#3d3d3d'
  }, [])

  let showImg = false
  let showImg2 = false
  let text1 = ''
  let text2 = ''
  let text3 = ``

  if (device == 'iOS' && browser == 'Safari on iOS') {
    showImg = 'iOS'
    showImg2 = 'iOS'
    text1 = '- how to download -'
    text2 = 'find share button'
    text3 = `add to homescreen`
  }

  if (device == 'Android' && browser == 'Chrome on Android') {
    showImg = 'Android'
    showImg2 = 'Android'
    text1 = '- how to download -'
    text2 = 'find chome menu'
    text3 = 'install app'
  }

  if (device == 'Desktop') {
    text1 = 'please visit website on phone'
    text2 = ''
    text3 = ''
  }
  if (device == 'iOS' && browser != 'Safari on iOS') {
    showImg = 'iOS'
    showImg2 = 'iOS'
    text1 = '- how to download -'
    text2 = 'find share button'
    text3 = `add to homescreen`
  }
  if (device == 'Android' && browser != 'Chrome on Android') {
    text1 = '- install -'
    text2 = 'please open with chrome'
  }
  return (
    <div style={{flex: 1}}>
      <div className={'flexCol'} style={{alignItems: 'center', justifyContent: 'center', height: topHalf}}>
        <div style={{alignSelf: 'stretch', textAlign: 'center', marginTop: -40}}>{`yoked`}</div>
        <div style={{border: '0px solid #bbb', borderBottomWidth: 1, width: 120, marginTop: 10}} />
        <div style={{alignSelf: 'stretch', textAlign: 'center', marginTop: 10}}>{`dignissimos ducimus qui blanditiis`}</div>
        <div style={{alignSelf: 'stretch', textAlign: 'center', marginTop: 6}}>{`sapiente delectus`}</div>
        <div style={{alignSelf: 'stretch', textAlign: 'center', marginTop: 6}}>{`omnis`}</div>
      </div>
      <div
        className={'flexCol'}
        style={{
          alignItems: 'center',
          alignSelf: 'stretch',
          justifyContent: 'center',
          height: 220,
          backgroundColor: '#3d3d3d',
          color: '#fff',
        }}>
        <div style={{alignSelf: 'stretch', textAlign: 'center', marginTop: 0}}>{text1}</div>
        <div style={{alignSelf: 'stretch', textAlign: 'center', marginTop: 14}}>
          {text2}
          {showImg == 'iOS' && (
            <img src={share} style={{height: 22, width: 22, opacity: 1, marginBottom: -4, marginLeft: 4}} />
          )}
          {showImg == 'Android' && (
            <img src={chromeMenu} style={{height: 18, width: 18, opacity: 0.9, marginBottom: -5, marginLeft: 4}} />
          )}
        </div>
        <div style={{alignSelf: 'stretch', textAlign: 'center', marginTop: 14}}>
          {text3}
          {showImg2 == 'iOS' && <img src={plusSquare} style={{height: 22, width: 22, marginBottom: -5, marginLeft: 5}} />}
          {showImg2 == 'Android' && <img src={phone} style={{height: 22, width: 22, marginBottom: -5, marginLeft: 4}} />}
        </div>
      </div>
    </div>
  )
}

let styles = {
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    border: '1px solid #888',
    alignSelf: 'baseline',
    paddingLeft: 24,
    paddingRight: 24,
    height: 40,
    backgroundColor: '#888',
    color: '#fff',
  },
  boxDivider: {
    color: '#fff',
    height: 25,
    border: '0px solid #ccc',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 2,
    borderRightWidth: 1,
  },
}

export default Install
