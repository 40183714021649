import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Axios from 'axios'

import {last5} from '../../util/helpers'

let Tiles = ({}) => {
  let [active, setActive] = useState(null)
  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)
  let friends = useSelector(state => state.friends)
  let [add, setAdd] = useState(false)
  let [days, setDays] = useState(last5())
  let [ppl, setPpl] = useState([])

  useEffect(() => {
    format()
  }, [user, friends])

  let format = () => {
    console.log('tiles format', ppl, days, user)

    //lifts + friends // gets 1 per day

    let daysNew = last5()

    let arr = user?.lifts?.length > 0 ? [user?.lifts] : []
    arr = arr.concat([]) // [friends]

    arr = arr.map(a => {
      let arr5 = daysNew.map((day, k) => {
        // find range
        const liftsForDay = a.filter(item => item.timestamp >= day.start && item.timestamp <= day.end)
        // find latest
        const latestLift = liftsForDay.reduce((latest, current) => {
          return latest === null || current.timestamp > latest.timestamp ? current : latest
        }, null)
        return latestLift || {id: '', name: '', timestamp: k}
      })

      arr5 = arr5.reverse()
      return arr5
    })
    setDays(daysNew)
    setPpl(arr)
  }

  let daysTop = days.map(a => {
    if (a.day == 'Sunday') return 'su'
    if (a.day == 'Monday') return 'm'
    if (a.day == 'Tuesday') return 't'
    if (a.day == 'Wednesday') return 'w'
    if (a.day == 'Thursday') return 'th'
    if (a.day == 'Friday') return 'f'
    if (a.day == 'Saturday') return 's'
  })
  daysTop = daysTop.reverse()
  daysTop = ['+'].concat(daysTop)

  let getRow = arr => {
    return arr.map((a, k) => {
      if (a.uid) {
        return (
          <div style={styles.photo} key={`0${k}`}>
            <div>{`me`}</div>
          </div>
        )
      }
      return (
        <div style={{...styles.tile, overflow: 'hidden'}} key={a.timestamp}>
          <div
            className={'flexCenter'}
            style={{
              fontSize: 33,
              marginBottom: -3,
              marginTop: -4,
              color: 'purple',
            }}>
            {a.num}
          </div>
          <div
            className={'flexCenter'}
            style={{paddingBottom: 3, alignSelf: 'stretch', backgroundColor: 'purple', color: '#fff', fontSize: 14}}>
            {a.name}
          </div>
        </div>
      )
    })
  }

  let showPpl = ppl?.map(a => {
    //puts picture with row
    let arr = [{uid: 123}].concat(a)
    return getRow(arr)
  })

  let table = showPpl?.map((a, k) => {
    return (
      <div key={k} className={'flexRow'} style={{alignSelf: 'stretch', marginBottom: 6}}>
        {a}
      </div>
    )
  })

  let week = daysTop.map(a => {
    if (a == '+') {
      return (
        <div key={a} style={styles.add} onClick={() => setAdd(!add)}>
          <div style={{opacity: 0.6, fontVariant: ['small-caps']}}>{add ? `-` : `+`}</div>
        </div>
      )
    }
    return (
      <div key={a} style={styles.weekTile}>
        <div style={{opacity: 0.6, fontVariant: ['small-caps']}}>{`${a}`}</div>
      </div>
    )
  })

  return (
    <div style={styles.page}>
      <div className={'flexRow'} style={{position: 'relative', alignSelf: 'stretch', marginBottom: 4}}>
        {week}
      </div>
      {add && (
        <div className={'flexCol'} style={{alignSelf: 'stretch', marginBottom: 2, marginTop: 4}}>
          <div style={styles.invRow}>add @</div>
          <div style={styles.invRow}>invite</div>
        </div>
      )}
      <div className={'flexCol'} style={{alignSelf: 'stretch', opacity: add ? 0.1 : 1}}>
        {table}
      </div>
    </div>
  )
}

let styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 330,
    alignSelf: 'center',
    marginBottom: 80,
  },
  photo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: 50,
    borderRadius: 6,
    border: '1px solid #888',
    color: '#888',
  },
  tile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 50,
    width: 50,
    borderRadius: 6,
    border: '1px solid #888',
    marginLeft: 6,
  },
  weekTile: {
    display: 'flex',
    flexDirection: 'column',
    height: 20,
    width: 50,
    borderRadius: 6,
    marginLeft: 6,
    alignItems: 'center',
  },
  add: {
    display: 'flex',
    flexDirection: 'column',
    height: 22,
    width: 50,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #888',
  },
  invRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    borderRadius: 6,
    border: '1px solid #888',
    marginBottom: 6,
    height: 42,
  },
}

export default Tiles
