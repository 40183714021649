import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Axios from 'axios'
import PullToRefresh from 'react-simple-pull-to-refresh'

import {getMe} from '../redux/actions'
import Auth from './Auth'
import Install from './Install'
import Menu from './Menu'
import downPng from '../imgs/down.png'

let Main = ({}) => {
  let dispatch = useDispatch()

  let device = useSelector(state => state.device)
  let isPwa = device?.includes('PWA')
  if (process.env.NODE_ENV === 'development') isPwa = true
  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)
  let loading = useSelector(state => state.loading)
  let [num, setNum] = useState(1)

  let handleRefresh = async () => {
    await dispatch(getMe())
  }

  //tabOut
  useEffect(() => {
    const seeU = async () => {
      const state = document.visibilityState
      if (state === 'hidden') {
        // Handle background state
      }
      if (state === 'visible') {
        console.log('Foreground')
        setNum(num => num + 1)
        await dispatch(getMe())
      }
    }

    document.addEventListener('visibilitychange', seeU)
    return () => {
      document.removeEventListener('visibilitychange', seeU)
    }
  }, [])

  let page
  if (loading) {
    page = <div />
  } else if (!isPwa) {
    page = <Install />
  } else if (!user?.state) {
    page = <Auth />
  } else {
    page = (
      <PullToRefresh
        resistance={2.5}
        onRefresh={handleRefresh}
        pullDownThreshold={40}
        refreshingContent={
          <div className={'flexRow'} style={{alignSelf: 'stretch', justifyContent: 'center', marginTop: 0, height: 24}}>
            <div className={'arc-hider'} />
          </div>
        }
        pullingContent={
          <div className={'flexRow'} style={{alignSelf: 'stretch', justifyContent: 'center', marginTop: 0, height: 24}}>
            <img src={downPng} style={{height: 20, width: 20, opacity: 0.2, marginLeft: 2}} />
          </div>
        }>
        <div className={'flexCol'} style={{alignSelf: 'stretch', alignItems: 'center'}}>
          <Menu />
          {num}
        </div>
      </PullToRefresh>
    )
  }

  return (
    <div
      className={'mainList lato300'}
      style={{
        alignSelf: 'stretch',
        paddingTop: 80,
        flex: 1,
      }}>
      {loading && (
        <div className={'flexRow'} style={{alignSelf: 'stretch', justifyContent: 'center'}}>
          <div className={'arc-hider'} />
        </div>
      )}
      {page}
    </div>
  )
}
export default Main
