import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import temple from '../imgs/temple.png'
import chat from '../imgs/chat.png'
import chat2 from '../imgs/chat2.png'
import user from '../imgs/user.png'
import logs from '../imgs/logs2.png'
import coin from '../imgs/coin.png'
import wallet from '../imgs/wallet.png'
import tiles from '../imgs/tiles.png'
import tilesH from '../imgs/tilesH.png'
import home from '../imgs/home.png'
import home2 from '../imgs/home2.png'
import plus from '../imgs/plus.png'

let Nav = () => {
  let dispatch = useDispatch()
  let screenWidth = useSelector(state => state.screenWidth)
  let screenHeight = useSelector(state => state.screenHeight)
  let device = useSelector(state => state.device)
  let user = useSelector(state => state.user)
  let tab = useSelector(state => state.tab)
  let modalShow = useSelector(state => state.modalShow)

  let isPwa = device?.includes('PWA')
  if (process.env.NODE_ENV === 'development') isPwa = true

  if (!isPwa || !user?.state) return null
  let setTab = tab => {
    dispatch({type: 'set', payload: {tab, showSideBar: false}})
  }

  let barLeft = 1000 //offscreen
  if (tab === 'wallet') {
    barLeft = 12
  }
  if (tab === 'home') {
    barLeft = 91
  }
  if (tab === 'chat') {
    barLeft = 172
  }

  let clickChat = () => {
    setTab('chat')
  }

  let toggleAdd = () => {
    let nextModal = 'add'
    if (modalShow) {
      nextModal = null
    }
    dispatch({type: 'set', payload: {modalShow: nextModal}})
  }

  let fullScreenIos = device?.includes('iOS') && screenHeight > 700
  return (
    <div
      className={'flexCol'}
      style={{
        width: screenWidth,
        height: fullScreenIos ? 74 : 60,
        backgroundColor: '#ddd',
        position: 'fixed',
        bottom: 0,
        alignSelf: 'stretch',
        alignItems: 'center',
        zIndex: 20,
      }}>
      <div style={{width: 240}}>
        <div
          className={'navBar'}
          style={{
            backgroundColor: '#000',
            height: 2,
            width: 58,
            transform: `translateX(${barLeft}px)`,
          }}
        />
      </div>
      <div
        className={'flexRow'}
        style={{
          marginTop: 10,
          alignSelf: 'stretch',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div onClick={() => toggleAdd()} className={'flexCenter'} style={{height: 50, width: 58, opacity: 0}} />
        <div className={'flexRow'} style={{width: 240, marginTop: -12}}>
          <div onClick={() => setTab('wallet')} className={'flexCenter'} style={{height: 36, width: 80}}>
            <img
              className={'navBarOp'}
              src={wallet}
              style={{
                height: 33,
                width: 34,
                opacity: tab == 'wallet' ? 0.74 : 0.5,
                marginBottom: 2,
              }}
            />
          </div>
          <div onClick={() => setTab('home')} className={'flexCenter'} style={{height: 36, width: 80}}>
            <img
              className={'navBarOp'}
              src={tiles}
              style={{
                height: 34,
                widht: 34,
                opacity: tab == 'home' ? 0.74 : 0.5,
              }}
            />
          </div>
          <div onClick={() => clickChat()} className={'flexCenter'} style={{height: 36, width: 80}}>
            <img
              className={'navBarOp'}
              src={false ? chat : chat2}
              style={{
                height: 33,
                widht: 34,
                opacity: tab == 'chat' ? 0.74 : 0.5,
              }}
            />
          </div>
        </div>
        <div
          onClick={() => toggleAdd()}
          className={'flexCenter'}
          style={{
            height: 61,
            width: 58,
            borderTopLeftRadius: 1,
            backgroundColor: '#777',
            marginTop: -13,
          }}>
          <img
            className={'navBarOp'}
            src={plus}
            style={{
              height: 33,
              widht: 34,
              opacity: 0.9,
              marginTop: -2,
              //marginRight: -1,
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default Nav
